import * as React from 'react'
import { Checkbox } from '@components/controls/checkbox'
import { useFormContext, useWatch } from 'react-hook-form'
import { useRules } from '@hooks/use-rules'
import { useEffect } from 'react'
import { AvailableRuleKeyword } from '@models/app-state'
import { MARKETING_RULES_KEYWORDS, MarketingAgreementSection } from '@components/agreement/marketing-agreement-section'

export const BASIC_RULES = [
  'services_rules',
  'main_rules',
  'email_rules',
  'sms_rules',
  'phone_call_rules',
  'information_clause',
]

interface Props {
  className?: string
  children?: React.ReactNode
  additionalRulesNames?: string[]
  productRules?: AvailableRuleKeyword[]
}

export const AgreementSection = ({
  className,
  children,
  additionalRulesNames = [],
  productRules,
}: Props): JSX.Element => {
  const { setValue, control, getValues } = useFormContext()

  const { getRules } = useRules()

  const rulesToCheck = [...additionalRulesNames, ...(productRules ?? BASIC_RULES)]

  const values = useWatch({ control, name: rulesToCheck })

  const isEachSelected = values.every(Boolean)

  useEffect(() => {
    const marketingRules = getValues(MARKETING_RULES_KEYWORDS)
    setValue('all', isEachSelected)
    setValue('allMarketingRulesSelected', isEachSelected || marketingRules.every(Boolean))
  }, [isEachSelected])

  const handleSelectAll = () => {
    rulesToCheck.forEach(additionalRule => {
      setValue(additionalRule, !isEachSelected)
    })
  }

  const [mainRules, ecommerceRules, informationClause] = getRules([
    'main_rules',
    'services_rules',
    'information_clause',
  ])

  const showRule = (rule: AvailableRuleKeyword) => rulesToCheck.some(el => el === rule)

  return (
    <div>
      <div className={className}>
        <Checkbox
          inputName="all"
          label="Zaznacz wszystkie"
          className="font-700 is-muted-label"
          onClick={handleSelectAll}
        />
        <div className="ms-4 pt-2 font-xs d-flex flex-column gap-2">
          {showRule('main_rules') && (
            <Checkbox
              className="checkbox--small is-muted-label"
              inputName="main_rules"
              label={
                <span>
                  Oświadczam, że zapoznałem się z treścią dokumentu
                  <a
                    className="text-darker-gray text-decoration-none fw-bold mx-1"
                    href={mainRules?.url}
                    target="_blank"
                  >
                    {mainRules?.name}
                  </a>
                  i akceptuję jego postanowienia.
                </span>
              }
            />
          )}
          {showRule('services_rules') && (
            <Checkbox
              className="checkbox--small is-muted-label"
              inputName="services_rules"
              label={
                <span>
                  Oświadczam, że zapoznałem się z treścią dokumentu
                  <a
                    className="text-darker-gray text-decoration-none fw-bold mx-1"
                    href={ecommerceRules?.url}
                    target="_blank"
                  >
                    {ecommerceRules?.name}
                  </a>
                  i akceptuję jego postanowienia.
                </span>
              }
            />
          )}
          {showRule('information_clause') && (
            <Checkbox
              inputName="information_clause"
              className="checkbox--small is-muted-label"
              label={
                <span>
                  Oświadczam, że zapoznałem się z treścią dokumentu
                  <a
                    className="text-darker-gray text-decoration-none fw-bold mx-1"
                    href={informationClause?.url}
                    target="_blank"
                  >
                    {informationClause?.name}
                  </a>
                  i akceptuję jego postanowienia.
                </span>
              }
            />
          )}
          {children}
          <MarketingAgreementSection />
        </div>
      </div>
    </div>
  )
}
