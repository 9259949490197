import * as React from 'react'
import { Checkbox } from '@components/controls/checkbox'
import { useFormContext, useWatch } from 'react-hook-form'
import { StaticRules } from '@helpers/rules'
import { ExpandableText } from '@components/expandable-text'

export const MARKETING_RULES_KEYWORDS = ['email_rules', 'sms_rules', 'phone_call_rules'] as const

export const MarketingAgreementSection = (): JSX.Element => {
  const { control, setValue } = useFormContext()

  const marketingRules = useWatch({ control, name: MARKETING_RULES_KEYWORDS })
  const selectedMarketingRules = marketingRules.filter(Boolean)

  const isAnyMarketingRuleSelected =
    selectedMarketingRules.length > 0 && selectedMarketingRules.length < MARKETING_RULES_KEYWORDS.length

  const handleAllSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked

    MARKETING_RULES_KEYWORDS.forEach(key => setValue(key, newValue))
  }

  React.useEffect(() => {
    const isEachSelected = marketingRules.every(Boolean)

    setValue('allMarketingRulesSelected', isEachSelected)
  }, [marketingRules])

  return (
    <>
      <Checkbox
        className="checkbox--small font-size-sm align-items-start is-muted-label"
        inputName="allMarketingRulesSelected"
        id="allMarketingRulesSelected"
        onChange={handleAllSelection}
        indeterminate={isAnyMarketingRuleSelected}
        label={
          <div className="mt-1">
            <div>Zgoda na przetwarzanie danych w celach marketingowych</div>
            <small className="d-block mt-1 col-12 col-md-7 text-pre-line">
              <ExpandableText text={StaticRules.marketing_rules} initialHeight={12} />
            </small>
          </div>
        }
      />

      <div className="mt-1" style={{ marginLeft: 30 }}>
        <p className="text-muted fw-semi-bold mb-1 col-lg-7 col-11">
          {StaticRules.marketing_rules_communication_channel}
        </p>

        <Checkbox
          inputName="phone_call_rules"
          id="phone_call_rules"
          label={<div className="mt-1 col-12 col-sm-8">telefonu</div>}
          className="checkbox--small font-size-sm fw-normal font-size-sm align-items-start is-muted-label"
        />
        <div className="mt-2 mb-1 fw-semi-bold text-muted pl-2 font-xs">
          środków komunikacji elektronicznej, w szczególności:
        </div>
        <Checkbox
          inputName="email_rules"
          id="email_rules"
          label={<div className="mt-1 col-12 col-sm-8">wiadomości email</div>}
          className="checkbox--small font-size-sm fw-normal font-size-sm align-items-start is-muted-label"
        />
        <Checkbox
          inputName="sms_rules"
          id="sms_rules"
          label={<div className="mt-1 col-12 col-sm-8">wiadomości SMS</div>}
          className="checkbox--small font-size-sm fw-normal font-size-sm align-items-start is-muted-label"
        />
      </div>
    </>
  )
}
