import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ClientVariant } from '@modules/offers/subscription-with-benefit/details/variants/subscription-with-benefit-variant-selection'
import { FormProvider, useForm } from 'react-hook-form'
import { Accommodation } from '@modules/offers/subscription-with-benefit/details/variants/card/subscription-with-benefit-variants-card'
import { OfferDetailsLayout } from '@layouts/offer-details-layout'
import { RESERVATION_APP_URLS } from '@helpers/consts'
import { OfferDetailsBaseInfo } from '@modules/offers/common/details/offer-details-base-info'
import { OfferRuleLink } from '@modules/offers/common/details/offer-rule-link'
import { GoHolidayAdditionalCostsInfo } from '@modules/offers/go-holiday/details/go-holiday-additional-costs-info'
import { GoHolidayPersonalData } from '@modules/offers/go-holiday/details/go-holiday-personal-data'
import { GoHolidayPayment } from '@modules/offers/go-holiday/details/go-holiday-payment'
import { GoHolidayAccommodationVariants } from '@modules/offers/go-holiday/details/go-holiday-accommodation-variants'
import { useGoHolidayGtmEvents } from '@modules/offers/go-holiday/use-go-holiday-gtm-events'
import { useAppSelector } from '@store/index'
import { selectAppState } from '@store/slices/app-slice'

interface FormInputs {
  email: string
  first_name: string
  last_name: string
  clientVariant: ClientVariant
  accommodation: Accommodation | null
  subscription_go_holiday_rules: boolean
  main_rules: boolean
  sms_rules: boolean
  email_rules: boolean
}

export const GoHolidayView = (): JSX.Element => {
  const { isAppReady } = useAppSelector(selectAppState)
  const { sentViewItemListEvent } = useGoHolidayGtmEvents()

  const resortGalleryRef = React.useRef<HTMLDivElement>(null)

  const methods = useForm<FormInputs>({
    defaultValues: {
      clientVariant: 'new_client',
      accommodation: null,
    },
    mode: 'onChange',
  })

  const handleScrollToResortsGallery = () => {
    resortGalleryRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  React.useEffect(() => {
    sentViewItemListEvent()
  }, [isAppReady])

  return (
    <OfferDetailsLayout
      questions={FAQ_QUESTIONS}
      ref={resortGalleryRef}
      resortGalleryTitle="Gdzie wykorzystać Vouchery?"
    >
      <FormProvider {...methods}>
        <OfferDetailsBaseInfo
          images={SLIDER_IMAGES}
          benefits={BENEFITS}
          title="GO!Holiday"
          description={
            <>
              <p>Skorzystaj ze specjalnej promocji na Pakiet 3 pobytów w domku, w ramach oferty GO!Holiday.</p>
              <p>
                Pobyty z Pakietu można realizować przez 5 lat w terminach{' '}
                <strong>od 01.01 do 31.05 oraz od 01.10 do 31.12</strong>
                <span className="text-nowrap"> z wyłączeniem Terminów Specjalnych</span>, we wszystkich dostępnych
                Resortach Holiday Park & Resort.
              </p>
            </>
          }
        >
          <GoHolidayAdditionalCostsInfo />
          <div className="d-flex flex-wrap">
            <OfferRuleLink keyword="subscription_go_holiday_rules" title="Regulamin akcji GO!Holiday" />
            <IconWithText
              text="Gdzie wykorzystasz pakiety?"
              iconClassName="uil-map-marker font-xxxl me-2 lh-1"
              textClassName="font-lg lh-1"
              className="fw-semi-bold ms-sm-5 mt-3 mt-sm-0"
              onClick={handleScrollToResortsGallery}
            />
          </div>
        </OfferDetailsBaseInfo>
        <GoHolidayAccommodationVariants />
        <GoHolidayPersonalData />
        <GoHolidayPayment />
      </FormProvider>
    </OfferDetailsLayout>
  )
}

const BENEFITS = [
  '3 pobyty 5-dniowe w domku dla 9 osób (max. 6 dorosłych)',
  'Dodatkowa specjalna pula lokali',
  'Możliwość odbycia krótszego pobytu w najwyższym sezonie',
  'Możliwość wykorzystania pakietu przez 5 lat',
  '7 resortów do wyboru',
]

const SLIDER_IMAGES = [
  {
    original: require('@assets/images/offers/go-holiday/gallery/coffe.webp'),
    thumbnail: require('@assets/images/offers/go-holiday/gallery/coffe.webp'),
  },
  {
    original: require('@assets/images/offers/go-holiday/gallery/swimming-pool.webp'),
    thumbnail: require('@assets/images/offers/go-holiday/gallery/swimming-pool.webp'),
  },
  {
    original: require('@assets/images/offers/go-holiday/gallery/table-football.webp'),
    thumbnail: require('@assets/images/offers/go-holiday/gallery/table-football.webp'),
  },
  {
    original: require('@assets/images/offers/go-holiday/gallery/volley-ball.webp'),
    thumbnail: require('@assets/images/offers/go-holiday/gallery/volley-ball.webp'),
  },
  {
    original: require('@assets/images/offers/go-holiday/gallery/waterpark.webp'),
    thumbnail: require('@assets/images/offers/go-holiday/gallery/waterpark.webp'),
  },
  {
    original: require('@assets/images/offers/go-holiday/gallery/yoga.webp'),
    thumbnail: require('@assets/images/offers/go-holiday/gallery/yoga.webp'),
  },
]

const FAQ_QUESTIONS = [
  {
    question: 'Ile mam czasu na wykorzystanie zakupionego Pakietu?',
    answer: 'Na wykorzystanie pobytów z pakietu masz czas do końca 2028 roku.',
  },
  {
    question: 'W jakich terminach mogę wykorzystać pobyty z Pakietu? ',
    answer: (
      <section className="font-md">
        <p>
          Możesz do nas przyjechać w terminach od 01.01 do 31.05 oraz od 01.10 do 31.12 z wyłączeniem terminów
          specjalnych.
        </p>
        Dodatkowo, istnieje możliwość realizacji krótszego pobytu (3 doby) w terminach od 25.08 do 28.08 lub od 28.08 do
        31.08.
      </section>
    ),
  },
  {
    question: 'Czym są terminy specjalne?',
    answer: (
      <section className="font-md">
        <p>
          Są to terminy wyłączone z oferty Pakietów, takie jak okres Świąt Wielkanocy i Bożego Narodzenia, Nowego Roku,
          Ferii zimowych czy Majówki lub weekendu Bożego Ciała.
        </p>
        <span className="font-sm">Szczegółowy wykaz terminów specjalnych znajdziesz w regulaminie oferty.</span>
      </section>
    ),
  },
  {
    question: 'W których resortach mogę wykorzystać vouchery?',
    answer:
      'Masz aż wszystkie 7 resortów do wyboru. Dodatkowo w: Pobierowie, Rowach i Uzdrowisku Cieplice Zdrój czekamy na Ciebie przez cały rok! ',
  },
  {
    question: 'Czy muszę założyć wszystkie rezerwacje już przy zakupie Pakietu?',
    answer:
      'Nie. Nie wymagamy od Ciebie deklaracji terminów od razu. Otrzymasz 3 vouchery (kody promocyjne), których następnie użyjesz przy zakładaniu rezerwacji.',
  },
  {
    question: 'Kiedy mogę zrealizować pobyty?',
    answer:
      'Każdy voucher (kod) możesz wykorzystać w dowolnym roku, natomiast istnieje możliwość odbycia maksymalnie jednego pobytu w danym roku kalendarzowym.',
  },
  {
    question: 'Skąd wiem czy jestem stałym klientem?',
    answer:
      'Stałym klientem jest osoba, która korzystała już z naszych usług związanych z pobytem w Resorcie i posiada Konto Gościa.',
  },
  {
    question: 'Jak mogę zapłacić za pakiet i ile mam czasu?',
    answer:
      'Korzystamy z bezpiecznych płatności Przelewy24. Sam wybierz wygodną dla siebie metodę płatności. Zapłać w ciągu 7 dni od złożenia zamówienia.',
  },
  {
    question: 'Kiedy otrzymam dostęp do zakupionego Pakietu?',
    answer:
      'Potwierdzenie e-mail wraz z listą kodów (voucherów pobytowych) otrzymasz od razu po zaksięgowaniu środków na naszym koncie bankowym. Cały proces zakupu może zająć zaledwie kilka minut!',
  },
  {
    question: 'Jak mogę założyć rezerwację? ',
    answer: (
      <section>
        Zachęcamy do skorzystania z wygodnego systemu rezerwacyjnego na naszej stronie internetowej pod adresem{' '}
        <a className="text-primary fw-bold text-decoration-none" href={RESERVATION_APP_URLS.MAIN_PAGE} target="_blank">
          rezerwuj.holidaypark.pl
        </a>
      </section>
    ),
  },
]
