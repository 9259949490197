import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import {
  ClientVariant,
  SubscriptionWithBenefitVariantSelection,
} from '@modules/offers/subscription-with-benefit/details/variants/subscription-with-benefit-variant-selection'
import { SubscriptionWithBenefitPersonalData } from '@modules/offers/subscription-with-benefit/details/personal-data/subscription-with-benefit-personal-data'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Accommodation,
  SubscriptionWithBenefitVariant,
} from '@modules/offers/subscription-with-benefit/details/variants/card/subscription-with-benefit-variants-card'
import { SubscriptionWithBenefitPayment } from '@modules/offers/subscription-with-benefit/details/subscription-with-benefit-payment'
import { SubscriptionWithBenefitAdditionalCostsInfo } from '@modules/offers/subscription-with-benefit/details/subscription-with-benefit-additional-costs-info'
import { useModal } from '@modals/use-modal'
import { OfferDetailsLayout } from '@layouts/offer-details-layout'
import { RESERVATION_APP_URLS } from '@helpers/consts'
import { OfferDetailsBaseInfo } from '@modules/offers/common/details/offer-details-base-info'
import { OfferRuleLink } from '@modules/offers/common/details/offer-rule-link'
import { subscriptionWithBenefitsUsageYears } from '@modules/offers/subscription-with-benefit/consts'
import { useSubscriptionWithBenefits } from '@modules/offers/subscription-with-benefit/details/use-subscription-with-benefits'
import { useSubscriptionWithBenefitsGtmEvents } from '@modules/offers/subscription-with-benefit/details/use-subscription-with-benefits-gtm-events'

interface FormInputs {
  email: string
  first_name: string
  last_name: string
  clientVariant: ClientVariant
  offerVariant: SubscriptionWithBenefitVariant | null
  offerVariantAccommodation: Accommodation | null
  subscription_with_benefit_rules: boolean
  main_rules: boolean
  sms_rules: boolean
  email_rules: boolean
}

export const SubscriptionWithBenefitView = (): JSX.Element => {
  const [showSubscriptionWithBenefitDescriptionModal] = useModal('SubscriptionWithBenefitDescriptionModal')

  const { sendViewItemListEvent } = useSubscriptionWithBenefitsGtmEvents()
  const { products } = useSubscriptionWithBenefits()

  const resortGalleryRef = React.useRef<HTMLDivElement>(null)

  const methods = useForm<FormInputs>({
    defaultValues: {
      clientVariant: 'new_client',
      offerVariant: null,
      offerVariantAccommodation: 'house',
    },
    mode: 'onChange',
  })

  const handleScrollToResortsGallery = () => {
    resortGalleryRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const rulesKey = products.lux
    ? products.lux.house.new_client[0].subscription_product.type === 'WITH_BENEFIT_20'
      ? 'subscription_with_benefit_20_rules'
      : 'subscription_with_benefit_rules'
    : 'subscription_with_benefit_rules'

  React.useEffect(() => {
    sendViewItemListEvent()
  }, [products])

  return (
    <OfferDetailsLayout
      questions={FAQ_QUESTIONS}
      ref={resortGalleryRef}
      resortGalleryTitle="Gdzie wykorzystać Pakiety Korzyści?"
    >
      <FormProvider {...methods}>
        <OfferDetailsBaseInfo
          images={SLIDER_IMAGES}
          benefits={BENEFITS}
          title="Wakacyjne Pakiety Korzyści od Holiday Park & Resort"
          description={
            <>
              Specjalna pula lokali do dyspozycji, tysiące złotych do wykorzystania w resortach, pakiet VIP w prezencie
              i wiele więcej!
              <span className="fw-semi-bold d-block mt-1">
                Sprawdź wszystkie korzyści i odpoczywaj tak, jak lubisz.
              </span>
            </>
          }
        >
          <div className="d-flex flex-wrap">
            <IconWithText
              text="Czym są Pakiety Pobytów?"
              iconClassName="uil-info-circle font-xxxl me-2 lh-1"
              textClassName="font-lg lh-1"
              className="text-secondary fw-semi-bold col-12 col-sm-auto"
              onClick={showSubscriptionWithBenefitDescriptionModal}
            />
            <IconWithText
              text="Gdzie wykorzystasz pakiety?"
              iconClassName="uil-map-marker font-xxxl me-2 lh-1"
              textClassName="font-lg lh-1"
              className="text-muted fw-semi-bold ms-sm-5 mt-3 mt-sm-0"
              onClick={handleScrollToResortsGallery}
            />
          </div>
          <SubscriptionWithBenefitAdditionalCostsInfo />
          <OfferRuleLink keyword={rulesKey} title="Regulamin akcji promocyjnej Wakacyjne Pakiety Korzyści" />
        </OfferDetailsBaseInfo>
        <SubscriptionWithBenefitVariantSelection />
        <SubscriptionWithBenefitPersonalData />
        <SubscriptionWithBenefitPayment />
      </FormProvider>
    </OfferDetailsLayout>
  )
}

const BENEFITS = [
  '5 pobytów 6-dniowych w domku lub apartamencie',
  'dodatkowa specjalna pula lokali',
  'pakiet VIP do ostatniej rezerwacji',
  'aż 5 lat na wykorzystanie pierwszego kodu',
  'nawet do 10 000 zł na zakupy podczas pobytów',
  'możliwość bezpłatnej zmiany terminu',
  'opcja podziału kodu na 2 krótsze pobyty',
  '7 resortów do wyboru ',
]

const SLIDER_IMAGES = [
  {
    original: require('@assets/images/offers/subscription-with-benefits/gallery/water-park-1.webp'),
    thumbnail: require('@assets/images/offers/subscription-with-benefits/gallery/water-park-1.webp'),
  },
  {
    original: require('@assets/images/offers/subscription-with-benefits/gallery/water-park-2.webp'),
    thumbnail: require('@assets/images/offers/subscription-with-benefits/gallery/water-park-2.webp'),
  },
  {
    original: require('@assets/images/offers/subscription-with-benefits/gallery/water-park-3.webp'),
    thumbnail: require('@assets/images/offers/subscription-with-benefits/gallery/water-park-3.webp'),
  },
  {
    original: require('@assets/images/offers/subscription-with-benefits/gallery/child-playground.webp'),
    thumbnail: require('@assets/images/offers/subscription-with-benefits/gallery/child-playground.webp'),
  },
  {
    original: require('@assets/images/offers/subscription-with-benefits/gallery/children.webp'),
    thumbnail: require('@assets/images/offers/subscription-with-benefits/gallery/children.webp'),
  },
  {
    original: require('@assets/images/offers/subscription-with-benefits/gallery/swimmin-pool.webp'),
    thumbnail: require('@assets/images/offers/subscription-with-benefits/gallery/swimmin-pool.webp'),
  },
]

const FAQ_QUESTIONS = [
  {
    question: 'Ile mam czasu na wykorzystanie zakupionego Pakietu?',
    answer: `Na wykorzystanie pobytów z pakietu masz 5 lat, do końca ${subscriptionWithBenefitsUsageYears.at(
      -1,
    )} roku.`,
  },
  {
    question: 'W jakich terminach mogę wykorzystać pobyty z Pakietu? ',
    answer: (
      <section className="font-md">
        <span>Terminy wykorzystania różnią się w zależności od wariantu oferty: </span>
        <ul className="list-unstyled ps-0 mt-1">
          <li>Pakiet Optimum - możesz do nas przyjechać od 01.01 do 31.05 oraz od 01.10 do 31.12*</li>
          <li>Pakiet Comfort - możesz do nas przyjechać od 01.01 do 30.06 oraz od 01.09 do 31.12*</li>
          <li>Pakiet Lux - możesz do nas przyjechać od 01.01 do 07.07 oraz od 26.08 do 31.12</li>
        </ul>
        <span className="d-block mt-2 font-sm">*Z wyłączeniem terminów specjalnych.</span>
      </section>
    ),
  },
  {
    question: 'Czym są terminy specjalne?',
    answer: (
      <section className="font-md">
        <p>
          Są to terminy wyłączone z oferty Pakietów Korzyści, takie jak okres Świąt Wielkanocy i Bożego Narodzenia,
          Nowego Roku, Ferii zimowych czy Majówki lub weekendu Bożego Ciała.
        </p>
        <span className="font-sm">Szczegółowy wykaz terminów specjalnych znajdziesz w regulaminie oferty.</span>
      </section>
    ),
  },
  {
    question: 'W których resortach mogę wykorzystać vouchery?',
    answer:
      'Masz aż wszystkie 7 resortów do wyboru. Dodatkowo w: Pobierowie, Rowach i Uzdrowisku Cieplice Zdrój czekamy na Ciebie przez cały rok! ',
  },
  {
    question: 'Czy muszę założyć wszystkie rezerwacje już przy zakupie Pakietu Korzyści?',
    answer:
      'Nie. Nie wymagamy od Ciebie deklaracji terminów od razu. Otrzymasz 5 voucherów (kodów promocyjnych), których następnie użyjesz przy zakładaniu rezerwacji.',
  },
  {
    question: 'Kiedy mogę zrealizować pobyty?',
    answer: `Pierwszy pobyt możesz zrealizować w latach ${
      subscriptionWithBenefitsUsageYears[0]
    } - ${subscriptionWithBenefitsUsageYears.at(
      -1,
    )}! Pozostałe 4 rezerwacje możesz odbyć kolejno w roku: ${subscriptionWithBenefitsUsageYears
      .slice(1, subscriptionWithBenefitsUsageYears.length)
      .join(', ')}.`,
  },
  {
    question: 'Skąd wiem czy jestem stałym klientem?',
    answer:
      'Stałym klientem jest osoba, która korzystała już z naszych usług związanych z pobytem w Resorcie i posiada Konto Gościa.',
  },
  {
    question: 'Jak mogę zapłacić za pakiet i ile mam czasu?',
    answer:
      'Korzystamy z bezpiecznych płatności Przelewy24. Sam wybierz wygodną dla siebie metodę płatności. Zapłać w ciągu 7 dni od złożenia zamówienia.',
  },
  {
    question: 'Kiedy otrzymam dostęp do zakupionego Pakietu?',
    answer:
      'Potwierdzenie e-mail wraz z listą kodów (voucherów pobytowych) otrzymasz od razu po zaksięgowaniu środków na naszym koncie bankowym. Cały proces zakupu może zająć zaledwie kilka minut!',
  },
  {
    question: 'Jak mogę założyć rezerwację? ',
    answer: (
      <section>
        Zachęcamy do skorzystania z wygodnego systemu rezerwacyjnego na naszej stronie internetowej pod adresem{' '}
        <a className="text-primary fw-bold text-decoration-none" href={RESERVATION_APP_URLS.MAIN_PAGE} target="_blank">
          rezerwuj.holidaypark.pl
        </a>
      </section>
    ),
  },
]
